// withTranslation

import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ClientDetailActionTypes } from '@app/store/pages/clientDetail/clientDetail/clientDetail.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'

import * as actions from '@app/store/pages/clientDetail/clientDetail/clientDetail.actions'

function* getClientDetailSaga(
  action: ReturnType<typeof actions.getClientDetailAction>,
) {
  try {
    const { data } = yield call(api.getClient, action.payload)

    yield put(actions.getClientDetailSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getClientDetail.notFound')
      404: 'errors.getClientDetail.notFound',
      // t('errors.getClientDetail.default')
      default: 'errors.getClientDetail.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getClientDetailFailureAction(error))
  }
}

function* patchClientSaga(
  action: ReturnType<typeof actions.patchClientAction>,
) {
  try {
    const { data } = yield call(api.patchClient, action.payload.id, {
      ...action.payload.partialClientDto,
    })

    yield put(
      addNotificationAction({
        // t('messages.patchClient.success')
        i18nextKey: 'messages.patchClient.success',
        type: 'success',
      }),
    )

    yield put(actions.patchClientSuccessAction(data))
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.patchClient.notFound')
      404: 'errors.patchClient.notFound',
      // t('errors.patchClient.conflict')
      409: 'errors.patchClient.conflict',
      // t('errors.patchClient.default')
      default: 'errors.patchClient.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.patchClientFailureAction(error))
  }
}

export default function* watchClientDetailSaga(): Generator {
  yield takeLatest(ClientDetailActionTypes.GetClientDetail, getClientDetailSaga)

  yield takeLatest(ClientDetailActionTypes.PatchClient, patchClientSaga)
}
