import axios, { AxiosResponse } from 'axios'
import { navigate } from 'gatsby'
import produce from 'immer'

import { LocalStorageKeys } from '@app/constants'

import {
  DocumentFileType,
  DocumentStatus,
  DocumentType,
  OfferStatuses,
  Routes,
  UserStatuses,
} from '@shared/enums'
import { UserDetailDto, UserDto } from '@shared/dto/user.dto'
import { OperatorDto } from '@shared/dto/operator.dto'
import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { CurrencyDto } from '@shared/dto/currency.dto'
import { CountryDto, PaginatedCountriesDto } from '@shared/dto/country.dto'
import { ContactPersonDetailDto } from '@shared/dto/contactPerson.dto'
import { CompanyDto } from '@shared/dto/company.dto'
import {
  AircraftReportsDto,
  BookingReportsDto,
  RequestReportsDto,
} from '@shared/dto/reports.dto'
import { GetSimilarRequestsDto } from '@shared/v2/common/dto/requests.dto'
import { AirportFeeDetailDto } from '@shared/dto/airportFees.dto'

import { UpdateOperatorSpecificUserDataDto } from '@shared/v2/common/dto/user.dto'

import {
  DefaultCreateEntityResponse,
  PaginatedDto,
} from '@shared/v2/common/dto/common.dto'

import {
  CreateFuelCostBody,
  ExportFuelCostQuery,
  FuelCostDto,
  GetFuelCostsQuery,
  UpdateFuelCostBody,
} from '@shared/v2/common/dto/fuel-cost.dto'

import {
  CreateOvernightFeeBody,
  ExportOvernightFeeQuery,
  GetOvernightFeeQuery,
  OvernightFeeDto,
  UpdateOvernightFeeBody,
} from '@shared/v2/common/dto/overnight-fee.dto'

import {
  InitialRegistrationResponseDto,
  InitRegistrationBodyDto,
  RegistrationBodyDto,
} from '@shared/dto/userRegistration.dto'

import {
  BaseLegDetailDto,
  ComputationResultDto,
  OfferDetailDto,
  OfferDto,
  RequestDetailDto,
} from '@shared/dto/requests.dto'

import {
  CreateOutageDto,
  GetBookingsReportsParams,
  GetChatParams,
  GetContactPersonsParams,
  GetContactPersonsResponse,
  GetCurrenciesParams,
  GetCustomRoutesParams,
  GetMyTeamListParams,
  GetRequestsListParams,
  GetRequestsListResponse,
  GetRequestsReportsParams,
  GetScheduleParams,
  GetScheduleResponse,
  InvitationDto,
  PatchCompanyDto,
  PatchMarketplaceExtensionDto,
  PostChangePasswordBodyParams,
  PostChatMessageBody,
  PostComputationResponse,
  PostContactPersonDto,
  PostCreateOffersFromComputationParams,
  PostCreateOffersFromComputationResponse,
  PostCustomRouteDto,
  PostRequestPasswordChangeBodyParams,
  TeamUserDto,
  UpdatePasswordDto,
  UpdateUserDisplaySettingsDto,
  UpdateUserDto,
} from '@app/utils/api/types'

import {
  ComputationInput,
  LegComputationRequest,
} from '@shared/interfaces/Computation'

import {
  CustomRouteDetailDto,
  PaginatedCustomRoutesListDto,
} from '@shared/dto/customRoutes.dto'

import { CreateRequestNoteBody } from '@shared/dto/requestNote.dto'
import { AviaCalculationRequestLegDto } from '@shared/modules/aviaPagesClient/aviaPages.interface'

import { Airway } from '@shared/entities/airway.entity'

import * as DTOs from '@shared/dto'
import {
  SaveAirwayRequestInput,
  SaveAirwayRequestOutput,
} from 'api/src/airway/airway.dto'
import { LegSearchParams } from '@shared/dto/leg.dto'

const GATSBY_BASE_API_URL = process.env.GATSBY_BASE_API_URL
const STORYBOOK_BASE_API_URL = process.env.STORYBOOK_BASE_API_URL

const instance = axios.create({
  baseURL: GATSBY_BASE_API_URL ?? STORYBOOK_BASE_API_URL,
})

enum ApiRoutes {
  Login = 'auth/login',
  Logout = 'auth/logout',
  RequestPasswordChange = 'auth/request-password-change',
  LogoutFromAllSessions = 'auth/logout-from-all-sessions',
  ResetPassword = 'auth/reset-password',
  Refresh = 'auth/refresh',
  ValidateToken = 'auth/validate-token',
  SwitchOperator = 'auth/switch-operator',
  Aircraft = 'aircraft',
  AirportFees = 'airport-fees',
  Client = 'clients',
  ContactPersons = 'contact-persons',
  Airports = 'airports',
  TechStops = 'airports/techstops',
  AirportNote = 'airport-note',
  Requests = 'requests',
  Offers = 'offers',
  Computations = 'computations',
  CreateOffersFromComputation = 'create-offers-from-computation',
  MyUser = 'my/user',
  MyOperators = 'my/operators',
  MyPassword = 'my/password',
  Schedule = 'schedule',
  CustomRoutes = 'custom-routes',
  Chat = 'chat',
  Currencies = 'currencies',
  Countries = 'countries',
  Operators = 'operators',
  Companies = 'companies',
  Reports = 'reports',
  Invitations = 'invitations',
  Users = 'users',
  NewMessages = 'chat/newMessages',
  UnreadMessegesCount = 'chat/unreadMessagesCount',
  MarkMessagesAsRead = 'chat/markMessagesAsRead',
  Documents = 'documents',
  AviaCalculator = 'avia-calculator',
  Airway = 'airway',
  AvoidedCountries = 'avoided-countries',
  AircraftReport = 'reports/aircraft',
}

const PUBLIC_API_ENDPOINTS = [
  'auth/login',
  'users/registration/init',
  'users/registration/submit',
  'countries',
  'currencies',
]

const createAxiosRequestInterceptor = () => {
  return instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(LocalStorageKeys.AuthToken)

      if (!token && config.url && !PUBLIC_API_ENDPOINTS.includes(config.url)) {
        navigate(Routes.Login)

        return config
      }

      config.headers.Authorization = 'Bearer ' + token

      return config
    },
    (error) => Promise.reject(error),
  )
}

const createAxiosResponseInterceptor = () => {
  const responseInterceptor = instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (
        error.response.status !== 401 ||
        error.config.url === ApiRoutes.Login
      ) {
        return Promise.reject(error)
      }

      instance.interceptors.response.eject(responseInterceptor)

      try {
        const { data } = await instance.post(ApiRoutes.Refresh)

        const { access_token } = data

        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token

        localStorage.setItem(LocalStorageKeys.AuthToken, access_token)

        const originalRequestResult = await instance(error.config)

        return originalRequestResult
      } catch (error) {
        localStorage.removeItem(LocalStorageKeys.AuthToken)

        navigate(Routes.Login)
      } finally {
        createAxiosResponseInterceptor()
      }
    },
  )

  return responseInterceptor
}

createAxiosResponseInterceptor()
createAxiosRequestInterceptor()

type Response<Data = unknown> = Promise<AxiosResponse<Data>>

export const api = {
  login: (
    email: string,
    password: string,
    preferedOperatorId?: number,
  ): Response<{ access_token: string; operatorId: number }> => {
    return instance.post(ApiRoutes.Login, {
      email,
      password,
      preferedOperatorId,
    })
  },

  logout: (): Response<void> => {
    return instance.post(ApiRoutes.Logout)
  },

  requestPasswordChange: (
    params: PostRequestPasswordChangeBodyParams,
  ): Response<void> => {
    return instance.post(ApiRoutes.RequestPasswordChange, params)
  },

  logoutFromAllSessions: (token: string): Response<void> => {
    return instance.post(ApiRoutes.LogoutFromAllSessions, { token })
  },

  resetPassword: (params: PostChangePasswordBodyParams): Response<void> => {
    return instance.post(ApiRoutes.ResetPassword, params)
  },

  validateToken: (params: { token: string }): Response<void> => {
    return instance.get(ApiRoutes.ValidateToken, { params })
  },

  switchOperator: (operatorId: number): Response<{ access_token: string }> => {
    return instance.post(ApiRoutes.SwitchOperator, { operatorId })
  },

  gerReservedLegs: (
    opts: LegSearchParams,
  ): Response<{ schedule: ScheduleDetailDto[] }> => {
    return instance.get(`/leg`, {
      params: {
        reserved: true,
        ...opts,
      },
    })
  },

  reserveOffer: (offerId: number, date: Date): Response<void> => {
    return instance.post(`/offers/${offerId}/reserve`, {
      reservationDate: date,
    })
  },

  deleteOfferReservation: (offerId: number): Response<void> => {
    return instance.delete(`/offers/${offerId}/reserve`)
  },

  getAircraftList: (
    params: DTOs.AircraftSearchParams,
  ): Response<DTOs.PaginatedList<DTOs.AircraftDetailDto>> => {
    return instance.get(ApiRoutes.Aircraft, { params })
  },

  getAircraftListCsv: (params: DTOs.AircraftSearchParams): Response<Blob> => {
    return instance.get(`${ApiRoutes.Aircraft}/export`, {
      params,
      responseType: 'blob',
    })
  },

  getClientsListCsv: (params: DTOs.GetClientsQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.Client}/export`, {
      params,
      responseType: 'blob',
    })
  },

  getAircraft: (id: number): Response<DTOs.AircraftDetailDto> => {
    return instance.get(`${ApiRoutes.Aircraft}/${id}`)
  },

  getAircraftImages: (id: number): Response<{ id: number }[]> => {
    return instance.get(`${ApiRoutes.Aircraft}/${id}/pictures`)
  },

  getAircraftImage: (aircraftId: number, id: number): Response<Blob> => {
    return instance.get(`${ApiRoutes.Aircraft}/${aircraftId}/picture/${id}`, {
      responseType: 'blob',
    })
  },

  uploadAircraftImages: (
    aircraftId: number,
    files: FileList,
    max: number = 6,
  ): Response<{ status: 'ok' | 'nok' }> => {
    const formData = new FormData()
    const allowed = Math.min(files.length, max)
    for (let i = 0; i < allowed; i++) {
      formData.append('files', files.item(i)!)
    }
    return instance.patch(
      `${ApiRoutes.Aircraft}/${aircraftId}/pictures`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  },

  deleteAircraftImages: (
    aircraftId: number,
    images: number[],
  ): Response<{ status: 'ok' | 'nok' }> => {
    return instance.delete(`${ApiRoutes.Aircraft}/${aircraftId}/pictures`, {
      data: {
        pictureIds: images,
      },
    })
  },

  postAircraft: (
    params: DTOs.AircraftDetailDto,
  ): Response<DTOs.AircraftDetailDto> => {
    return instance.post(ApiRoutes.Aircraft, params)
  },

  patchAircraft: (
    id: number,
    params: DTOs.PartialAircraftDto,
  ): Response<DTOs.AircraftDetailDto> => {
    return instance.patch(`${ApiRoutes.Aircraft}/${id}`, params)
  },

  copyAircraft: (
    id: number,
    params: DTOs.CopyAircraftRequestBody,
  ): Response<{ id: number }> => {
    return instance.post(ApiRoutes.Aircraft + `/${id}/copy`, params)
  },

  deleteAircraft: (id: number): Response => {
    return instance.delete(`${ApiRoutes.Aircraft}/${id}`)
  },

  getAirports: (
    params: DTOs.GetAirportsQuery,
  ): Response<DTOs.PaginatedList<DTOs.AirportDetailDto>> => {
    return instance.get(`${ApiRoutes.Airports}`, { params })
  },

  getMapAirports: (
    params: DTOs.GetAirportsMapQuery,
  ): Response<DTOs.AirportMapDto[]> => {
    return instance.get(`${ApiRoutes.Airports}/map`, { params })
  },

  getAirportsCsv: (params: DTOs.GetAirportsQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.Airports}/export`, {
      responseType: 'blob',
      params,
    })
  },

  getTechStopAirports: (params: {
    tech_stops: string
  }): Response<DTOs.AirportDetailDto[]> => {
    return instance.get(`${ApiRoutes.Airports}/techstops`, { params })
  },

  getAirport: (id: number): Response<DTOs.AirportDetailDto> => {
    return instance.get(`${ApiRoutes.Airports}/${id}`)
  },

  getClients: (
    params: DTOs.GetClientsQuery,
  ): Response<DTOs.PaginatedList<DTOs.ClientDetailDto>> => {
    return instance.get(ApiRoutes.Client, { params })
  },

  getClient: (id: number): Response<DTOs.ClientDetailDto> => {
    return instance.get(`${ApiRoutes.Client}/${id}`)
  },

  postClient: (
    client: DTOs.CreateClientDto,
  ): Response<DTOs.ClientDetailDto> => {
    return instance.post(ApiRoutes.Client, client)
  },

  patchClient: (
    id: number,
    data: DTOs.UpdateClientDto,
  ): Response<DTOs.ClientDetailDto> => {
    return instance.patch(`${ApiRoutes.Client}/${id}`, data)
  },

  deleteClient: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Client}/${id}`)
  },

  getContactPersons: (
    params: GetContactPersonsParams,
  ): Response<GetContactPersonsResponse> => {
    return instance.get(ApiRoutes.ContactPersons, { params })
  },

  postContactPerson: (
    contactPerson: PostContactPersonDto,
  ): Response<{ id: number }> => {
    return instance.post(ApiRoutes.ContactPersons, contactPerson)
  },

  patchContactPerson: (
    id: number,
    data: Partial<PostContactPersonDto>,
  ): Response<ContactPersonDetailDto> => {
    return instance.patch(`${ApiRoutes.ContactPersons}/${id}`, data)
  },

  deleteContactPerson: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.ContactPersons}/${id}`)
  },

  getRequestsList: (
    params: GetRequestsListParams,
  ): Response<GetRequestsListResponse> => {
    return instance.get(ApiRoutes.Requests, { params })
  },

  getRequestsCsv: (
    params: Omit<GetRequestsListParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Requests}/export`, {
      params,
      responseType: 'blob',
    })
  },

  bulkDeclineRequests: (params: number[]): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/bulk/decline`, { ids: params })
  },

  getContactPersonsCsv: (
    params: Omit<GetContactPersonsParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`contact-persons/export`, {
      params,
      responseType: 'blob',
    })
  },

  getRequest: (id: number, operatorId?: number): Response<RequestDetailDto> => {
    return instance.get(`${ApiRoutes.Requests}/${id}?operator_id=${operatorId}`)
  },

  patchRequest: (
    requestId: number,
    params: Pick<Partial<RequestDetailDto>, 'contact_person_id' | 'client_id'>,
  ) => {
    return instance.patch(`${ApiRoutes.Requests}/${requestId}`, params)
  },

  postComputation: (
    params: ComputationInput,
  ): Response<PostComputationResponse> => {
    return instance.post(ApiRoutes.Computations, params)
  },

  addRequestNote: (params: CreateRequestNoteBody) => {
    return instance.patch(
      [ApiRoutes.Requests, params.request_id, 'add-note'].join('/'),
      params,
    )
  },

  getComputation: (id: number): Response<ComputationResultDto> => {
    return instance.get(`${ApiRoutes.Computations}/${id}`)
  },

  createOffersFromComputation: (
    params: PostCreateOffersFromComputationParams,
  ): Response<PostCreateOffersFromComputationResponse> => {
    return instance.post(ApiRoutes.CreateOffersFromComputation, params)
  },

  getOffer: (offerId: number): Response<OfferDetailDto> => {
    return instance.get(`/offers/${offerId}`)
  },

  getOffers: (offerIds: number[]): Response<OfferDetailDto[]> => {
    return offerIds.length > 0
      ? instance.get(`/offers/multiple/${offerIds.join(',')}`)
      : Promise.resolve({
          data: [] as OfferDetailDto[],
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        })
  },

  updateOfferStatus: (
    offerId: number,
    nextStatus: OfferStatuses,
    shouldSendEmail?: boolean,
    userNote?: string,
    cancellationFee?: number | null,
    shouldSendToAvinode?: boolean,
  ) => {
    type Payload = {
      status: OfferStatuses
      user_note?: string
      should_send_email?: boolean
      should_send_to_avinode?: boolean
      cancellation_fee?: number
    }

    const payload: Payload = {
      status: nextStatus,
      should_send_email: shouldSendEmail,
      should_send_to_avinode: shouldSendToAvinode,
      user_note: userNote,
    }

    return instance.put(
      `/offers/${offerId}/status`,
      produce(payload, (draft) => {
        if (Number.isFinite(cancellationFee)) {
          draft.cancellation_fee = cancellationFee as number
        }
      }),
    )
  },

  patchOffer: (
    offerId: number,
    // @todo Create dedicated Patch DTO
    partialOffer: Omit<Partial<OfferDto>, 'legs'> & {
      legs: BaseLegDetailDto[]
    },
  ): Response<OfferDto> => {
    return instance.patch(`/offers/${offerId}`, partialOffer)
  },

  deleteOffer: (offerId: number): Response<void> => {
    return instance.delete(`/offers/${offerId}`)
  },

  getUsers: (params: GetMyTeamListParams): Response<UserDetailDto[]> => {
    return instance.get(`${ApiRoutes.Users}`, {
      params,
    })
  },

  getUsersCsv: (
    params: Omit<GetMyTeamListParams, 'limit' | 'page'> & { lang: string },
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Users}/export`, {
      params,
      responseType: 'blob',
    })
  },

  putUserStatus: (
    userId: number,
    operatorId: number,
    status: UserStatuses,
  ): Response<void> => {
    return instance.put(
      `${ApiRoutes.Users}/${userId}/status`,
      { status },
      { params: { operator_id: operatorId } },
    )
  },

  getUser: (id: number): Response<UserDetailDto[]> => {
    return instance.get(`${ApiRoutes.Users}/${id}`)
  },

  getMyUser: (): Response<UserDto> => {
    return instance.get(ApiRoutes.MyUser)
  },

  updateOperatorSpecificUserData: (
    operatorId: number,
    data: UpdateOperatorSpecificUserDataDto,
  ) => {
    return instance.patch(`/my/user/${operatorId}`, data)
  },

  createOrUpdateAirportNote: (data: DTOs.CreateAirportNoteBody) => {
    return instance.patch(ApiRoutes.AirportNote, data)
  },

  getAirportNotes: (): Response<DTOs.AirportNote[]> => {
    return instance.get(ApiRoutes.AirportNote)
  },

  getNewMessages: (
    newerThanSeconds: number = 60,
  ): Response<DTOs.ChatMessageDto[]> => {
    return instance.get(ApiRoutes.NewMessages, {
      params: { newerThanSeconds },
    })
  },

  getUnreadMessagesCount: (): Response<number> => {
    return instance.get(ApiRoutes.UnreadMessegesCount)
  },

  markMessagesAsRead: (
    request: DTOs.MarkMessagesAsReadDto,
  ): Response<{ success: boolean; message?: string }> => {
    return instance.get(ApiRoutes.MarkMessagesAsRead, {
      params: request,
    })
  },

  registerUser: (id: number, partialUser: UpdateUserDto): Response<UserDto> => {
    return instance.patch(`${ApiRoutes.Users}/${id}`, partialUser)
  },

  patchMyUser: (
    partialUser: UpdateUserDto | UpdateUserDisplaySettingsDto,
  ): Response<UserDto> => {
    return instance.patch(ApiRoutes.MyUser, partialUser)
  },

  getMyOperators: (): Response<OperatorDto[]> => {
    return instance.get(ApiRoutes.MyOperators)
  },

  putMyPassword: (params: UpdatePasswordDto): Response<void> => {
    return instance.put(ApiRoutes.MyPassword, params)
  },

  declineRequest: (requestId: number): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/${requestId}/decline`)
  },

  getScheduleList: (
    params: GetScheduleParams,
  ): Response<GetScheduleResponse> => {
    return instance.get(ApiRoutes.Schedule, { params })
  },

  getScheduleListCsv: (
    params: Omit<GetScheduleParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Schedule}/export`, {
      params,
      responseType: 'blob',
    })
  },

  postOutage: (params: CreateOutageDto): Response<{ id: number }> => {
    return instance.post(`${ApiRoutes.Schedule}/outage`, params)
  },

  patchOutage: (
    id: number,
    params: Partial<CreateOutageDto>,
  ): Response<ScheduleDetailDto> => {
    return instance.patch(`${ApiRoutes.Schedule}/outage/${id}`, params)
  },

  deleteOutage: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Schedule}/outage/${id}`)
  },

  patchMarketplaceExtension: (
    id: number,
    params: PatchMarketplaceExtensionDto,
  ): Response<ScheduleDetailDto> => {
    return instance.patch(
      `${ApiRoutes.Schedule}/${id}/marketplace-extension`,
      params,
    )
  },

  deleteMarketplaceExtension: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Schedule}/${id}/marketplace-extension`)
  },

  getCustomRoutes: (
    params: GetCustomRoutesParams,
  ): Response<PaginatedCustomRoutesListDto> => {
    return instance.get(`${ApiRoutes.CustomRoutes}`, { params })
  },

  getCustomRoutesCsv: (
    params: Omit<GetCustomRoutesParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.CustomRoutes}/export`, {
      params,
      responseType: 'blob',
    })
  },

  postCustomRoute: (body: PostCustomRouteDto): Response<{ id: number }> => {
    return instance.post(ApiRoutes.CustomRoutes, body)
  },

  patchCustomRoute: (
    id: number,
    body: PostCustomRouteDto,
  ): Response<CustomRouteDetailDto> => {
    return instance.patch(`${ApiRoutes.CustomRoutes}/${id}`, body)
  },

  deleteCustomRoute: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.CustomRoutes}/${id}`)
  },

  getAirportFees: (
    params: DTOs.GetAirportFeesQuery,
  ): Response<DTOs.PaginatedList<DTOs.AirportFeeDto>> => {
    return instance.get(ApiRoutes.AirportFees, { params })
  },

  exportAirportFees: (params: DTOs.GetAirportFeesQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.AirportFees}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createAirportFee: (
    body: DTOs.CreateAirportFeeBody,
  ): Response<DefaultCreateEntityResponse> => {
    return instance.post(ApiRoutes.AirportFees, body)
  },

  importAirportFees: (
    body: DTOs.ImportAirportFeesBody,
    file: File,
  ): Response<DefaultCreateEntityResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    body.aircraft_id &&
      formData.append('aircraft_id', body.aircraft_id.toString())
    return instance.post(`${ApiRoutes.AirportFees}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  updateOperatorImage: (
    file: File,
    operatorId: number,
  ): Response<{ status: 'ok' | 'nok' }> => {
    const formData = new FormData()
    formData.append('file', file)
    return instance.patch(`operators/${operatorId}/logo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  getOperatorImage: (operatorId: number): Response<Blob> => {
    return instance.get(`operators/${operatorId}/logo`, {
      responseType: 'blob',
    })
  },

  updateAirportFee: (
    id: number,
    data: DTOs.UpdateAirportFeeBody,
  ): Response<AirportFeeDetailDto> => {
    return instance.patch(`${ApiRoutes.AirportFees}/${id}`, data)
  },

  deleteAirportFee: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.AirportFees}/${id}`)
  },

  getChat: (
    params: GetChatParams,
  ): Response<DTOs.PaginatedList<DTOs.ChatMessageDto>> => {
    return instance.get(`${ApiRoutes.Chat}`, {
      params,
    })
  },

  postChatMessage: (body: PostChatMessageBody): Response<{ id: number }> => {
    return instance.post(`${ApiRoutes.Chat}`, body)
  },

  postMarkChatMessageAsRead: ({
    requestId,
    latestMessageId,
  }: {
    requestId: number
    latestMessageId: number
  }): Response => {
    return instance.post(
      `${ApiRoutes.Requests}/${requestId}/chat/mark-as-read`,
      { latest_read_chat_message_id: latestMessageId },
    )
  },

  getCurrencies: (
    params: GetCurrenciesParams,
  ): Response<DTOs.PaginatedList<CurrencyDto>> => {
    return instance.get(ApiRoutes.Currencies, { params })
  },

  getCountries: (
    params: GetCurrenciesParams,
  ): Response<PaginatedCountriesDto> => {
    return instance.get(ApiRoutes.Countries, { params })
  },

  getCountryByCode: (code: string): Response<CountryDto> => {
    return instance.get(`${ApiRoutes.Countries}/code/${code}`)
  },

  postOperator: (
    postOperatorDto: Partial<OperatorDto>,
  ): Response<OperatorDto> => {
    return instance.post(ApiRoutes.Operators, postOperatorDto)
  },

  patchOperator: (
    id: number,
    partialOperatorDto: Partial<OperatorDto>,
  ): Response<OperatorDto> => {
    return instance.patch(`${ApiRoutes.Operators}/${id}`, partialOperatorDto)
  },

  cancelOffer: (id: number): Response<OfferDetailDto> => {
    return instance.post(`${ApiRoutes.Offers}/${id}/cancel`)
  },

  getCompany: (id: number): Response<CompanyDto> => {
    return instance.get(`${ApiRoutes.Companies}/${id}`)
  },

  patchCompany: (
    id: number,
    patchCompanyDto: PatchCompanyDto,
  ): Response<CompanyDto> => {
    return instance.patch(`${ApiRoutes.Companies}/${id}`, patchCompanyDto)
  },

  getRequestReports: (
    params: GetRequestsReportsParams,
  ): Response<RequestReportsDto> => {
    return instance.get(`${ApiRoutes.Reports}/requests`, { params })
  },

  getBookingReports: (
    params: GetBookingsReportsParams,
  ): Response<BookingReportsDto> => {
    return instance.get(`${ApiRoutes.Reports}/bookings`, { params })
  },

  postInvitation: (postInvitationDto: InvitationDto) => {
    return instance.post(`${ApiRoutes.Invitations}`, postInvitationDto)
  },

  postResendInvitation: (operatorId: number, userId: number) => {
    return instance.post(`${ApiRoutes.Invitations}/resend`, {
      operator_id: operatorId,
      user_id: userId,
    })
  },

  patchUser: (userId: number, updateTeamUser: TeamUserDto) => {
    return instance.patch(`${ApiRoutes.Users}/${userId}`, updateTeamUser)
  },

  initRegistration: (
    data: InitRegistrationBodyDto,
  ): Response<InitialRegistrationResponseDto> => {
    return instance.post(`users/registration/init`, data)
  },

  submitRegistration: (
    data: RegistrationBodyDto,
  ): Response<{ access_token: string }> => {
    return instance.post(`users/registration/submit`, data)
  },

  rebookOffer: (offerId: number, data: LegComputationRequest[]) => {
    return instance.post(`offers/${offerId}/rebook`, {
      requests: data,
    })
  },

  fetchSimilarRequests: (
    requestId: number,
  ): Response<GetSimilarRequestsDto> => {
    return instance.get(`requests/${requestId}/similar`)
  },

  fetchFuelCosts(
    query: GetFuelCostsQuery,
  ): Response<PaginatedDto<FuelCostDto>> {
    return instance.get('fuel-costs', { params: query })
  },

  exportFuelCosts(query: ExportFuelCostQuery): Response<Blob> {
    return instance.get('fuel-costs/export', { params: query })
  },

  createFuelCost(
    data: CreateFuelCostBody,
  ): Response<DefaultCreateEntityResponse> {
    return instance.post('fuel-costs', data)
  },

  updateFuelCost(id: number, data: UpdateFuelCostBody): Response<FuelCostDto> {
    return instance.patch(`fuel-costs/${id}`, data)
  },

  deleteFuelCost(id: number): Response<undefined> {
    return instance.delete(`fuel-costs/${id}`)
  },

  fetchOvernightFees(
    query: GetOvernightFeeQuery,
  ): Response<PaginatedDto<OvernightFeeDto>> {
    return instance.get('overnight-fees', { params: query })
  },

  exportOvernightFees(query: ExportOvernightFeeQuery): Response<Blob> {
    return instance.get('overnight-fees/export', { params: query })
  },

  createOvernightFee(
    data: CreateOvernightFeeBody,
  ): Response<DefaultCreateEntityResponse> {
    return instance.post('overnight-fees', data)
  },

  updateOvernightFee(
    id: number,
    data: UpdateOvernightFeeBody,
  ): Response<OvernightFeeDto> {
    return instance.patch(`overnight-fees/${id}`, data)
  },

  deleteOvernightFee(id: number): Response<undefined> {
    return instance.delete(`overnight-fees/${id}`)
  },

  generateOfferDocument(body: {
    offer_id: number
    type: DocumentType
    fileType: DocumentFileType
    user: UserDto | null
  }): Response<{ id: number; status: DocumentStatus }> {
    return instance.post(`${ApiRoutes.Documents}/generate`, body)
  },

  getDocumentStatus(body: {
    id: number
  }): Response<{ status: DocumentStatus }> {
    return instance.get(`${ApiRoutes.Documents}/status`, {
      params: { document_id: body.id },
    })
  },

  downloadDocument(body: { id: number }): Response<Blob> {
    return instance.get(`${ApiRoutes.Documents}/download`, {
      responseType: 'blob',
      params: { document_id: body.id },
    })
  },

  generateAviaPageCalculation(body: {
    leg: AviaCalculationRequestLegDto
    avoid_countries?: string[]
  }): Response<DTOs.AviaCalculationsResponseDto> {
    return instance.post(`${ApiRoutes.AviaCalculator}`, body)
  },

  saveAirway(body: SaveAirwayRequestInput): Response<SaveAirwayRequestOutput> {
    return instance.post(`${ApiRoutes.Airway}`, body)
  },

  getAirway(id: number): Response<Airway> {
    return instance.get(`${ApiRoutes.Airway}/${id}`)
  },

  getMultipleAirways(ids: number[]): Response<Airway[]> {
    return ids.length > 0
      ? instance.get(`${ApiRoutes.Airway}`, {
          params: { ids: ids.join(',') },
        })
      : Promise.resolve({
          data: [] as Airway[],
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        })
  },

  getAvoidedCountries(aircraftId?: number): Response<DTOs.AvoidedCountry[]> {
    return instance.get(`${ApiRoutes.AvoidedCountries}`, {
      params: { aircraftId },
    })
  },

  createAvoidedCountry(body: DTOs.CreateAvoidedCountryInput): Response<void> {
    return instance.post(`${ApiRoutes.AvoidedCountries}`, body)
  },

  updateAvoidedCountry(
    body: DTOs.UpdateAvoidedCountryInput,
    id: string,
  ): Response<void> {
    return instance.patch(`${ApiRoutes.AvoidedCountries}/${id}`, body)
  },

  deleteAvoidedCountry(id: string): Response<void> {
    return instance.delete(`${ApiRoutes.AvoidedCountries}/${id}`)
  },

  getAircraftReport(params: {
    operator_id: string
    from_date: Date
    to_date: Date
  }): Response<AircraftReportsDto[]> {
    return instance.get(`${ApiRoutes.AircraftReport}`, { params })
  },
}
