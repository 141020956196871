// withTranslation

import { call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { ClientsActionTypes } from '@app/store/api/clients/clients.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { reloadClientsAction } from '@app/store/pages/clients/clientsList/clientsList.actions'

import * as actions from '@app/store/api/clients/clients.actions'

function* postClientSaga(action: ReturnType<typeof actions.postClientAction>) {
  try {
    const operator = selectSelectedOperator(yield select())

    if (!operator) {
      throw new Error('Operator is required')
    }

    const { data } = yield call(api.postClient, action.payload.postClientDto)

    yield put(
      addNotificationAction({
        // t('messages.postClient.success')
        i18nextKey: 'messages.postClient.success',
        type: 'success',
      }),
    )

    yield put(actions.postClientSuccessAction(data))

    yield put(reloadClientsAction())
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.postClient.conflict')
      409: 'errors.postClient.conflict',
      // t('errors.postClient.default')
      default: 'errors.postClient.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.postClientFailureAction(error))
  }
}

export default function* watchClientDetailSaga(): Generator {
  yield takeLatest(ClientsActionTypes.PostClient, postClientSaga)
}
